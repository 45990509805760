<template>
  <!-- Main Sidebar -->
  <a-layout-sider
    collapsible
    class="sider-primary"
    breakpoint="lg"
    collapsed-width="0"
    width="250px"
    :collapsed="sidebarCollapsed"
    @collapse="$emit('toggleSidebar', !sidebarCollapsed)"
    :trigger="null"
    :class="[
      'ant-layout-sider-' + sidebarColor,
      'ant-layout-sider-' + sidebarTheme,
    ]"
    theme="light"
    :style="{ backgroundColor: 'transparent' }"
  >
    <div class="brand">
      <img src="images/finku-logo.png" alt="" /> <span>Finku Dashboard</span>
    </div>
    <hr />

    <!-- Sidebar Navigation Menu -->
    <a-menu theme="light" mode="inline">
      <a-menu-item v-for="item in sideNavItems" :key="item.link">
        <router-link :to="item.link" style="text-decoration: none">
          <span class="icon">
            <img :src="item.icon" :alt="`${item.label}-icon`" />
          </span>
          <span class="label">{{ item.label }}</span>
        </router-link>
      </a-menu-item>
      <a-menu-item class="menu-item-header"> Finku App Management </a-menu-item>
    </a-menu>
    <!-- / Sidebar Navigation Menu -->
  </a-layout-sider>
  <!-- / Main Sidebar -->
</template>

<script>
const FINKU_SIDE_NAV_ITEMS = [
  // {
  //   icon: "images/icons/dashboard-icon.svg",
  //   label: "Dashboard",
  //   link: "/dashboard",
  // },
  // {
  //   icon: "images/icons/referral-icon.svg",
  //   label: "Referral",
  //   link: "/referral",
  // },
  // {
  //   icon: "images/icons/gamification-icon.svg",
  //   label: "Gamification",
  //   link: "/gamification",
  // },
  // {
  //   icon: "images/icons/app-management-icon.svg",
  //   label: "App Management",
  //   link: "/app-management",
  // },
  // {
  //   icon: "images/icons/user-management-icon.svg",
  //   label: "User Management",
  //   link: "/user-management",
  // },
  // {
  //   icon: "images/icons/push-notification-icon.svg",
  //   label: "Push Notifications",
  //   link: "/push-notifications",
  // },
  // {
  //   icon: "images/icons/financial-account-icon.svg",
  //   label: "Financial Account",
  //   link: "/financial-account",
  // },
  // {
  //   icon: "images/icons/experimentation-icon.svg",
  //   label: "Experimentation",
  //   link: "/experimentation",
  // },
  {
    icon: "images/icons/rating-icon.svg",
    label: "Rating",
    link: "/rating",
  },
  // {
  //   icon: "images/icons/loan-users-icon.svg",
  //   label: "All Lending Users",
  //   link: "/all-lending-users",
  // },
  {
    icon: "images/icons/loan-users-icon.svg",
    label: "Loan Users",
    link: "/loan-users",
  },
  {
    icon: "images/icons/loan-withdraw-icon.svg",
    label: "Loan Withdraw",
    link: "/loan-withdraw",
  },
  // {
  //   icon: "images/icons/loan-document-icon.svg",
  //   label: "Loan Document",
  //   link: "/loan-document",
  // },
  {
    icon: "images/icons/manual-review-icon.svg",
    label: "Manual Review",
    link: "/manual-review",
  },
  {
    icon: "images/icons/loan-document-icon.svg",
    label: "Limit Increase",
    link: "/limit-increase",
  },
  // {
  //   icon: "images/icons/repayment-ticket-icon.svg",
  //   label: "Repayment Ticket",
  //   link: "/repayment-ticket",
  // },
  // {
  //   icon: "images/icons/budget-reset-icon.svg",
  //   label: "Budget Reset",
  //   link: "/budgets",
  // },
  // {
  //   icon: "images/icons/vcn-users-icon.svg",
  //   label: "VCN Users",
  //   link: "/vcn-users",
  // },
];

const SEMBRANI_SIDE_NAV_ITEMS = [
  // {
  //   icon: "images/icons/dashboard-icon.svg",
  //   label: "Dashboard",
  //   link: "/dashboard",
  // },
  // {
  //   icon: "images/icons/vcn-users-icon.svg",
  //   label: "VCN Users",
  //   link: "/vcn-users",
  // },
];

export default {
  props: {
    // Sidebar collapsed status.
    sidebarCollapsed: {
      type: Boolean,
      default: false,
    },

    // Main sidebar color.
    sidebarColor: {
      type: String,
      default: "primary",
    },

    // Main sidebar theme : light, white, dark.
    sidebarTheme: {
      type: String,
      default: "light",
    },
  },
  data() {
    return {
      // sidebarCollapsedModel: this.sidebarCollapsed,
    };
  },
  computed: {
    userEmail() {
      return this.$store.getters.userEmail || "";
    },
    sideNavItems() {
      if (!this.userEmail) {
        return SEMBRANI_SIDE_NAV_ITEMS;
      } else {
        if (this.userEmail.includes("sfin.co.id")) {
          return SEMBRANI_SIDE_NAV_ITEMS;
        }
        return FINKU_SIDE_NAV_ITEMS;
      }
    },
  },
};
</script>
