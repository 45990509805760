import Vue from "vue";
import VueRouter from "vue-router";
import store from "../store";

Vue.use(VueRouter);

let routes = [
  {
    // will match everything
    path: "*",
    component: () => import("../views/404.vue"),
  },
  {
    path: "/",
    name: "Home",
    redirect: "/sign-in",
    meta: {
      guest: true,
    },
  },
  {
    path: "/dashboard",
    name: "Dashboard",
    layout: "dashboard",
    component: () => import("../views/Dashboard.vue"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/referral",
    name: "Referral",
    layout: "dashboard",
    component: () => import("../views/Referral.vue"),
    meta: {
      internal: true,
      requiresAuth: true,
    },
  },
  {
    path: "/gamification",
    name: "Gamification",
    layout: "dashboard",
    component: () => import("../views/Gamification.vue"),
    meta: {
      internal: true,
      requiresAuth: true,
    },
  },
  {
    path: "/app-management",
    name: "App Management",
    layout: "dashboard",
    component: () => import("../views/AppManagement.vue"),
    meta: {
      internal: true,
      requiresAuth: true,
    },
  },
  {
    path: "/user-management",
    name: "User Management",
    layout: "dashboard",
    component: () => import("../views/UserManagement.vue"),
    meta: {
      internal: true,
      requiresAuth: true,
    },
  },
  {
    path: "/push-notifications",
    name: "Push Notifications",
    layout: "dashboard",
    component: () => import("../views/PushNotifications.vue"),
    meta: {
      internal: true,
      requiresAuth: true,
    },
  },
  {
    path: "/financial-account",
    name: "Financial Account",
    layout: "dashboard",
    component: () => import("../views/FinancialAccount.vue"),
    meta: {
      internal: true,
      requiresAuth: true,
    },
  },
  {
    path: "/experimentation",
    name: "Experimentation",
    layout: "dashboard",
    component: () => import("../views/Experimentation.vue"),
    meta: {
      internal: true,
      requiresAuth: true,
    },
  },
  {
    path: "/rating",
    name: "Rating",
    layout: "dashboard",
    component: () => import("../views/Rating.vue"),
    meta: {
      internal: true,
      requiresAuth: true,
    },
  },
  {
    path: "/loan-users",
    name: "Loan Users",
    layout: "dashboard",
    component: () => import("../views/LoanWaitlist/LoanWaitlist.vue"),
    meta: {
      internal: true,
      requiresAuth: true,
    },
  },
  {
    path: "/all-lending-users",
    name: "All Lending Users",
    layout: "dashboard",
    component: () => import("../views/AllLendingUsers/AllLendingUsers.vue"),
    meta: {
      internal: true,
      requiresAuth: true,
    },
  },
  {
    path: "/loan-withdraw",
    name: "Loan Withdraw",
    layout: "dashboard",
    component: () => import("../views/LoanWithdraw.vue"),
    meta: {
      internal: true,
      requiresAuth: true,
    },
  },
  {
    path: "/loan-document",
    name: "Loan Document",
    layout: "dashboard",
    component: () => import("../views/LoanDocument.vue"),
    meta: {
      internal: true,
      requiresAuth: true,
    },
  },
  {
    path: "/manual-review",
    name: "Manual Review",
    layout: "dashboard",
    component: () => import("../views/ManualReview.vue"),
    meta: {
      internal: true,
      requiresAuth: true,
    },
  },
  {
    path: "/limit-increase",
    name: "Update Limit",
    layout: "dashboard",
    component: () => import("../views/LimitIncrease/LimitIncrease.vue"),
    meta: {
      internal: true,
      requiresAuth: true,
    },
  },
  {
    path: "/repayment-ticket",
    name: "Repayment Ticket",
    layout: "dashboard",
    component: () => import("../views/LoanRepayment.vue"),
    meta: {
      internal: true,
      requiresAuth: true,
    },
  },
  {
    path: "/budgets",
    name: "Budgets",
    layout: "dashboard",
    component: () => import("../views/Budget.vue"),
    meta: {
      internal: true,
      requiresAuth: true,
    },
  },
  {
    path: "/vcn-users",
    name: "VCN Users",
    layout: "dashboard",
    component: () => import("../views/VcnUsers/VcnUsers.vue"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/sign-in",
    name: "Sign-In",
    component: () => import("../views/Sign-In.vue"),
    meta: { guest: true },
  },
];

// Adding layout property from each route to the meta
// object so it can be accessed later.
function addLayoutToRoute(route, parentLayout = "default") {
  route.meta = route.meta || {};
  route.meta.layout = route.layout || parentLayout;

  if (route.children) {
    route.children = route.children.map((childRoute) =>
      addLayoutToRoute(childRoute, route.meta.layout)
    );
  }
  return route;
}

routes = routes.map((route) => addLayoutToRoute(route));

const router = new VueRouter({
  mode: "hash",
  base: process.env.BASE_URL,
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (to.hash) {
      return {
        selector: to.hash,
        behavior: "smooth",
      };
    }
    return {
      x: 0,
      y: 0,
      behavior: "smooth",
    };
  },
});

router.beforeEach((to, from, next) => {
  if (to.matched.some((record) => record.meta.requiresAuth)) {
    if (store.getters.isAuthenticated) {
      if (to.matched.some((record) => record.meta.internal)) {
        if (store.getters.userEmail.includes("sfin.co.id")) {
          next("/dashboard");
          return;
        }
        next();
        return;
      } else {
        next();
        return;
      }
    }
    next("/sign-in");
  } else {
    next();
  }
});

router.beforeEach((to, from, next) => {
  if (to.matched.some((record) => record.meta.guest)) {
    if (store.getters.isAuthenticated) {
      next("/dashboard");
      return;
    }
    next();
  } else {
    next();
  }
});

export default router;
